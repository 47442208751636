import React from "react"

const Wordpressshowcase = () => {
  return (
    <div class="showcase">
    <div class="showcase-container">
      <div class="gallery-wrap">
        <div class="item item-1"></div>
        <div class="item item-2"></div>
        <div class="item item-3"></div>
        <div class="item item-4"></div>
        <div class="item item-5"></div>
      </div>
    </div>
    </div>
  );
}

export default Wordpressshowcase

import React from "react"
import { Typography, Card } from "antd"
import { Animated } from "react-animated-css"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import Layout from "../../components/Layout"
import SideForm from "../../components/SideForm"
import Showcasewordpress from "../../components/Wordpressshowcase"
import "../../styles/services.scss"
import "../../styles/animate.css"
import "../../styles/wordpress_showcase.scss"

const { Title } = Typography
const Index = ({ data }) => {
  const backgroundImage = data.bgImage.childImageSharp.fluid.src
  return (
    <Layout className="services-page-container" data={data} 
    pageTitle={"Visiomate - Wordpress Website Development Services"}
    descrip = {"Craft your online presence with expert WordPress development. We build custom, high-performing websites that drive results. Contact us today!"}>
      <div className="top-section"
        style={{ backgroundImage: `url(${backgroundImage})` }}>
        <div className="logo">
          <Link to="/">
            <Img fluid={data.VMLogoWhite.childImageSharp.fluid} />
          </Link>
        </div>
        <div className="box-wrapper text-and-form-container">
          <div className="information">
            <Animated
              animationIn="fadeInUp"
              animationInDuration={500}
              animationInDelay={300}
              isVisible={true}
            >
              <Title>Strengthen Your Online Presence with WordPress Website</Title>
              <p>
                Today, having a strong online presence is essential for any business to succeed, and WordPress provides a quick and easy way to establish a website.  <br /> <br />
                TBusinesses can embrace fresh avenues for growth, visibility, and success in the constantly developing digital arena.
              </p>
            </Animated>
          </div>
          <Animated
            animationIn="fadeInDown"
            animationInDuration={800}
            animationInDelay={500}
            isVisible={true}
            className="form"
          >
            <SideForm />
          </Animated>
        </div>
      </div>
      <div className="heading-and-detail">
        <Title>WordPress Website Development services</Title>
        <p>
          Our specialty is creating custom WordPress websites that offer a dynamic online environment where customers can quickly find and connect with your brand, leading to meaningful engagement and growth.
        </p>
      </div>
      <div className="cards-wrapper-wordpress">
        <Card title="Websites for businesses">
          <p>
            Great for displaying goods, services, and corporate details.
          </p>
        </Card>
        <Card title="E-commerce Websites">
          <p>
            Websites for online sales of goods and services that include elements like shopping carts and secure payment gateways.
          </p>
        </Card>
        <Card title="Educational Websites">
          <p>
            Online learning environments that host tutorials, courses, and other educational materials.
          </p>
        </Card>
        <Card title="Membership websites">
          <p>
            These are online communities that provide members with access to premium services or content, usually through a subscription model.
          </p>
        </Card>
        <Card title="Blogs">
          <p>
            Platforms that allow users to share content, including news, articles, and opinions; frequently includes social sharing and commenting options.
          </p>
        </Card>
        <Card title="Portfolio Websites">
          <p>
            Platforms that showcase the work of creatives such as photographers, designers, and artists.
          </p>
        </Card>
        <Card title="Event Websites">
          <p>
            Platforms for the management and promotion of conferences, workshops, and get-togethers.
          </p>
        </Card>
        <Card title="Real Estate Websites">
          <p>
            Platforms that help with property searches, list properties, and link buyers with sellers or agents.
          </p>
        </Card>
      </div>
      <Showcasewordpress />
    </Layout>
  )
}

export default Index

export const wordpressQuery = graphql`
  query wordpressQuery {
    FarmManagementSystem: file(
      relativePath: { eq: "FarmManagementSystem/pf.jpg" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ISOComplianceManagement: file(
      relativePath: { eq: "ISOComplianceManagement/iso.png" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    TenderAssetRecordManagement: file(
      relativePath: { eq: "TenderAssetRecordManagement/tender.jpg" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ViztosPos: file(relativePath: { eq: "ViztosPos/vistos_image.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Odoo1: file(relativePath: { eq: "Odoo/odoo1.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Odoo2: file(relativePath: { eq: "Odoo/odoo2.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Odoo3: file(relativePath: { eq: "Odoo/odoo3.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Custom: file(relativePath: { eq: "Custom/CustDevelopment.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    D365: file(relativePath: { eq: "D365/D365.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    D365_2: file(relativePath: { eq: "D365/D365_2.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Support: file(relativePath: { eq: "safty.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Analysis: file(relativePath: { eq: "analysis.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Analysis2: file(relativePath: { eq: "analysis2.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Development: file(relativePath: { eq: "development.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Connect: file(relativePath: { eq: "connect.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Data: file(relativePath: { eq: "data.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    VMLogoBlack: file(relativePath: { eq: "logo_black_LST.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    VMLogoWhite: file(relativePath: { eq: "logo_white_LST.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bgImage: file(relativePath: { eq: "Mask_group8.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    EMS: file(relativePath: { eq: "EMS/ems_side.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Viztos: file(relativePath: { eq: "ViztosPos/viztos_side.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Tender: file(relativePath: { eq: "TenderAssetRecordManagement/trn_side.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
